import PropTypes from 'prop-types'

import { Link } from 'gatsby'
import styled from '@emotion/styled'

const Button = styled(Link)`
  border: 1px solid;
  padding: 1rem 2rem;
  color: ${props => (props.inverted ? 'white' : 'black')};
  background: ${props => (props.inverted ? 'black' : 'white')};
  font-weight: 600;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  font-size: 1.1rem;
  max-width: 380px;
  display: flex;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
    pointer-events: none;
    background: #eee;
  }

  &:hover {
    background-color: ${props => (props.inverted ? 'white' : 'black')};
    color: ${props => (props.inverted ? 'black' : 'white')};
    border: 1px solid black;
    fill: white;
  }

  @media (max-width: 400px) {
    width: 100%;
  }
`

Button.propTypes = {
  inverted: PropTypes.bool,
}

Button.defaultProps = {
  inverted: false,
}

export default Button
